var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"course_video"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"video_area"},[_c('div',{staticClass:"head"},[_c('span',{staticClass:"back",on:{"click":function($event){return _vm.$router.push({
                path: '/courseDetail',
                query: {
                  courseId: _vm.$route.query.courseId,
                  idx: _vm.$route.query.index,
                },
              })}}},[_c('i',{staticClass:"iconfont"},[_vm._v("")]),_vm._v("返回")]),_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.currentChapter.title))])]),_c('div',{attrs:{"id":"dplayer"}}),(_vm.noVideo)?_c('img',{staticClass:"cover",attrs:{"src":_vm.courseInfo.img,"alt":""}}):_vm._e()]),_c('el-tabs',{staticClass:"right_menu",attrs:{"type":"border-card"},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"章节目录","name":"1"}},[_c('div',{staticClass:"total"},[_vm._v(" 章节目录（共"+_vm._s(_vm.courseInfo.chapterCount)+"章） ")]),_c('div',{staticClass:"chapters"},[_vm._l((_vm.chapterTree),function(c){return [_c('div',{key:c.chapterId,staticClass:"one_chapter1"},[_vm._v(_vm._s(c.title))]),_vm._l((c.children),function(chapter){return _c('div',{key:chapter.chapterId,staticClass:"one_chapter",class:{
                  playing: chapter.chapterId == _vm.currentChapter.chapterId,
                  disabled: !chapter.open
                },on:{"click":function($event){chapter.open && _vm.playVideo(chapter.chapterId, true)}}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(chapter.title))]),(!chapter.open)?_c('i',{staticClass:"el-icon-lock",staticStyle:{"float":"right","font-size":"16px","line-height":"44px"}}):[_c('span',{directives:[{name:"show",rawName:"v-show",value:(chapter.videoId),expression:"chapter.videoId"}],staticClass:"time"},[_vm._v(_vm._s(_vm.formatVideoTime(chapter.videoTime)))]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(
                      chapter.videoId &&
                      chapter.chapterId != _vm.currentChapter.chapterId
                    ),expression:"\n                      chapter.videoId &&\n                      chapter.chapterId != currentChapter.chapterId\n                    "}],staticClass:"iconfont play"},[_vm._v("")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(
                      chapter.chapterId == _vm.currentChapter.chapterId &&
                      chapter.videoId
                    ),expression:"\n                      chapter.chapterId == currentChapter.chapterId &&\n                      chapter.videoId\n                    "}],staticClass:"iconfont play"},[_vm._v("")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!chapter.videoId),expression:"!chapter.videoId"}],staticClass:"iconfont no_video",attrs:{"title":"无视频"}},[_vm._v("")])]],2)})]})],2)]),_c('el-tab-pane',{attrs:{"label":"互动聊天","name":"2"}},[_c('Chat')],1)],1)],1),_c('div',{staticClass:"bottom"},[_c('el-tabs',{staticClass:"tab",on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-tab-pane',{attrs:{"label":"PPT","name":"1","lazy":""}},[_c('ppt',{attrs:{"chapterId":_vm.currentChapter.chapterId}})],1),_c('el-tab-pane',{attrs:{"label":"文档","name":"2"}},[_c('docs',{attrs:{"chapterId":_vm.currentChapter.chapterId}})],1),_c('el-tab-pane',{attrs:{"label":"随堂练习","name":"3"}},[_c('test',{attrs:{"chapterId":_vm.currentChapter.chapterId}})],1),_c('el-tab-pane',{attrs:{"label":"资料下载","name":"4"}},[_c('downloadFile',{attrs:{"chapterId":_vm.currentChapter.chapterId}})],1),_c('el-tab-pane',{attrs:{"label":"任务","name":"5"}},[_c('Activity',{attrs:{"courseId":_vm.$route.query.courseId}})],1),(_vm.courseInfo.showPythonTools)?_c('el-tab-pane',{attrs:{"label":"Python在线","name":"6","lazy":""}},[_c('python')],1):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }