<template>
  <div class="container">
    <el-tabs
      v-model="currentClassId"
      @tab-click="handleClick"
      tabPosition="left"
    >
      <el-tab-pane
        v-for="c in classList"
        :key="c.classId"
        :label="c.className"
        :name="c.classId + ''"
        >
            <XList :courseId="courseId" :classId="c.classId" />
        </el-tab-pane
      >
    </el-tabs>
  </div>
</template>
<script>
import { myCourseClass } from "@/api/class";
import XList from './list'
export default {
    components: {
        XList
    },
  props: ["courseId"],
  data() {
    return {
      currentClassId: null,
      classList: [],
    };
  },
  created() {
    this.getClassList();
  },
  methods: {
    handleClick(tab) {
      tab.$children[0].getList()
    },
    getClassList() {
      myCourseClass(this.courseId).then((res) => {
        if (res.code == 200) {
          this.classList = res.data;
          if (this.classList.length > 0) {
            this.currentClassId = this.classList[0].classId + '';
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  min-height: 500px;
}
</style>