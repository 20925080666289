<template>
  <el-dialog
    class="grade_popup"
    :visible.sync="show"
    width="400px"
    :before-close="handleClose"
  >
    <img :src="img" alt="" />
    <p v-if="!isPaper" class="grade">
      得分<span>{{ score }}</span
      >分
    </p>

    <div class="paper_text" v-else>
      <p class="success">交卷成功</p>
      <p class="tips" v-show="showTips">
        本次考试允许重考{{ maxReexaminationNum }}次，已重考{{
          reexaminationNum
        }}次
      </p>
    </div>
    <div class="btn_bar">
      <button :class="{ two_style: allowReexamination }" @click="lookUp">
        查看试卷
      </button>
      <button
        :class="{ small: allowReexamination }"
        v-show="allowReexamination"
        @click="reexamination"
      >
        重考
      </button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "resultDialog",
  props: {
    // 控制弹窗显现
    show: {
      type: Boolean,
      default: false,
    },
    // 空状态图片
    img: {
      type: String,
      default: require("@/assets/img/jiaojuanchenggong.png"),
    },
    // 是否试卷
    isPaper: {
      type: Boolean,
      defalut: true,
    },
    // 得分
    score: {
      type: Number,
      defalut: 0,
    },
    // 显示重考提示
    showTips: {
      type: Boolean,
      defalut: false,
    },
    // 允许重考次数
    maxReexaminationNum: {
      type: Number,
      defalut: 0,
    },
    // 重考次数
    reexaminationNum: {
      type: Number,
      defalut: 0,
    },
    // 控制重考按钮显现
    allowReexamination: {
      type: Boolean,
      defalut: false,
    },
  },
  methods: {
    // 关闭
    handleClose() {
      this.$emit("close");
    },
    // 查看试卷按钮事件
    lookUp() {
      this.$emit("lookUp");
    },
    // 重考按钮事件
    reexamination() {
      this.$emit("reexamination");
    },
  },
};
</script>

<style lang="less" scoped>
.grade_popup {
  text-align: center;
  .grade {
    margin-top: 30px;
    margin-bottom: 44px;
    font-size: 14px;
    color: #999;
    span {
      margin: 0 5px;
      font-size: 43px;
      color: #333;
    }
  }
  .paper_text {
    margin-top: 30px;
    margin-bottom: 27px;
    .success {
      font-size: 18px;
      color: #333;
      font-weight: bold;
    }
    .tips {
      margin-top: 7px;
      font-size: 12px;
      color: #999;
    }
  }
  .btn_bar {
    button {
      width: 182px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      color: #fff;
      background: #0d79ff;
      border-radius: 19px;
      &.small {
        width: 150px;
      }
      &.two_style {
        margin-right: 20px;
        width: 150px;
        color: #0d79ff;
        border: 1px solid #0d79ff;
        background-color: #fff;
      }
    }
  }
}
</style>