<template>
  <div class="select">
    <div class="no_data" v-if="list.length == 0">暂无选中人员</div>
    <div class="select-box">
      <ul>
        <li v-for="m in list" :key="m.memberId">
          <div class="p-info">
            <div class="img-box">
              <img
                :src="m.memberIcon || require('@/assets/img/defaultavatar.png')"
                alt=""
              />
              <span>{{ m.score }}</span>
            </div>
          </div>
          <p class="p-name">{{ m.nickName }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { selectList } from "@/api/activity";
export default {
  props: {
    activity: Object,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
    this.timer = setInterval(() => {
      if (this.activity.status == 1) {
        this.getList();
      }
    }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getList() {
      selectList(this.activity.activityId).then((json) => {
        this.list = json.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.select {
  height: 100%;
  .no_data {
    line-height: 420px;
    text-align: center;
    font-size: 14px;
    color: #a8a8b3;
  }

  .select-box {
    padding: 30px 86px 104px;
    text-align: center;
    ul {
      li {
        display: inline-block;
        width: 120px;
        margin-right: 8px;
        margin-top: 15px;
        height: 145px;
        vertical-align: top;

        .p-info {
          position: relative;
          margin-bottom: 10px;
          .img-box {
            width: 80px;
            height: 80px;
            box-sizing: border-box;
            position: relative;
            overflow: hidden;
            border-radius: 40px;
            margin: 0 auto;
            img {
              width: 100%;
              height: 100%;
              border-radius: 40px;
            }
            span {
              height: 32px;
              line-height: 32px;
              width: 100%;
              position: absolute;
              left: 0;
              bottom: 0;
              font-size: 18px;
              color: #ffffff;
              background: rgba(51, 159, 255, 0.8);
            }
          }
        }

        .p-name {
          font-size: 18px;
          color: #646873;
          -o-ellipsis-lastline: ellipsis;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>