<template>
  <div class="container" v-loading="loading">
    <div class="docs" v-if="list.length > 0">
      <!-- <div > -->
      <el-collapse
        v-show="!current.docId"
        :value="list.map((item) => item.docId)"
      >
        <el-collapse-item
          disabled
          v-for="p in list"
          :key="p.docId"
          :title="p.name"
          :name="p.docId"
        >
          <div
            class="docs-item"
            v-for="d in p.children"
            :key="d.docId"
            @click="current = d"
          >
            {{ d.name }}
          </div>
        </el-collapse-item>
      </el-collapse>
      <!-- </div> -->
      <div v-show="current.docId">
        <el-page-header
          class="image-header"
          @back="current = {}"
          :content="current.name"
        >
        </el-page-header>
        <el-image v-for="(img, i) in images" :key="i" :src="img" />
      </div>
    </div>
    <empty-status
      :img="require('@/assets/img/emptyIcon/wushouchang@2x.png')"
      desc="暂无文档~"
      v-else
    />
  </div>
</template>
<script>
import { getDocs } from "@/api/curriculum";
import { handleTree } from "@/utils/treeShape";
import emptyStatus from "@/components/emptyStatus/index";
export default {
  components: {
    emptyStatus,
  },
  props: {
    chapterId: Number,
  },
  data() {
    return {
      loading: false,
      list: [],
      current: {},
    };
  },
  watch: {
    chapterId() {
      this.getDocs();
    },
  },
  computed: {
    images() {
      return this.current.imageUrls || [];
    },
  },
  methods: {
    getDocs() {
      this.loading = true;
      getDocs({ chapterId: this.chapterId }).then((json) => {
        this.loading = false;
        const list = json.rows.map((item) => ({
          ...item,
          parentId: item.parentId || 0,
        }));

        this.list = handleTree(list, "docId");
        console.log(this.list);
      });
    },
  },
};
</script>
<style scoped>
.container {
  min-height: 300px;
}
.docs {
  margin: 20px 40px;
  text-align: left;
}
.docs-item {
  font-size: 18px;
  padding: 16px 6px;
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  border-color: #ccc;
  cursor: pointer;
}
.docs-item:hover {
  background: #f6f6f6;
}
.image-header {
  margin-bottom: 20px;
}
</style>