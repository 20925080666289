<template>
  <div class="course_video">
    <div class="container">
      <div class="top">
        <div class="video_area">
          <div class="head">
            <span
              class="back"
              @click="
                $router.push({
                  path: '/courseDetail',
                  query: {
                    courseId: $route.query.courseId,
                    idx: $route.query.index,
                  },
                })
              "
              ><i class="iconfont">&#xe625;</i>返回</span
            >
            <h2 class="title">{{ currentChapter.title }}</h2>
          </div>
          <div id="dplayer"></div>
          <img v-if="noVideo" class="cover" :src="courseInfo.img" alt="" />
        </div>
        <el-tabs class="right_menu" v-model="activeName" type="border-card">
          <el-tab-pane label="章节目录" name="1">
            <div class="total">
              章节目录（共{{ courseInfo.chapterCount }}章）
            </div>
            <div class="chapters">
              <template v-for="c in chapterTree">
                <div :key="c.chapterId" class="one_chapter1">{{ c.title }}</div>
                <div
                  class="one_chapter"
                  :class="{
                    playing: chapter.chapterId == currentChapter.chapterId,
                    disabled: !chapter.open
                  }"
                  v-for="chapter in c.children"
                  :key="chapter.chapterId"
                  @click="chapter.open && playVideo(chapter.chapterId, true)"
                >
                  <!-- <span class="index">{{ formatNumber(idx + 1) }}</span> -->
                  <p class="title">{{ chapter.title }}</p>
                  <i v-if="!chapter.open" class="el-icon-lock" style="float: right;font-size: 16px;line-height: 44px"/>
                  <template v-else>
                    <span v-show="chapter.videoId" class="time">{{
                      formatVideoTime(chapter.videoTime)
                    }}</span>
                    <i
                      v-show="
                        chapter.videoId &&
                        chapter.chapterId != currentChapter.chapterId
                      "
                      class="iconfont play"
                      >&#xe62f;</i
                    >
                    <i
                      v-show="
                        chapter.chapterId == currentChapter.chapterId &&
                        chapter.videoId
                      "
                      class="iconfont play"
                      >&#xe62e;</i
                    >
                    <i
                      v-show="!chapter.videoId"
                      class="iconfont no_video"
                      title="无视频"
                      >&#xe615;</i
                    >
                  </template>
                </div>
              </template>
            </div>
          </el-tab-pane>
          <el-tab-pane label="互动聊天" name="2">
            <Chat />
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="bottom">
        <el-tabs class="tab" v-model="activeTab" @tab-click="handleClick">
          <!-- 课程介绍 -->
          <el-tab-pane label="PPT" name="1" lazy>
            <ppt :chapterId="currentChapter.chapterId" />
          </el-tab-pane>
          <el-tab-pane label="文档" name="2">
            <docs :chapterId="currentChapter.chapterId" />
          </el-tab-pane>
          <el-tab-pane label="随堂练习" name="3">
            <test :chapterId="currentChapter.chapterId" />
          </el-tab-pane>

          <el-tab-pane label="资料下载" name="4">
            <downloadFile :chapterId="currentChapter.chapterId" />
          </el-tab-pane>
          <el-tab-pane label="任务" name="5">
            <Activity :courseId="$route.query.courseId" />
          </el-tab-pane>
          <el-tab-pane label="Python在线" name="6" v-if="courseInfo.showPythonTools" lazy>
            <python />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { getCourseDetail, videoAddress } from "@/api/curriculum";
import { formatVideoTime, formatNumber } from "@/utils/index";
import Chat from "@/components/chat/index.vue";
import DPlayer from "dplayer";
import test from "./test";
import ppt from "./ppt";
import docs from "./docs";
import python from './python';
import downloadFile from "./downloadFile";
import Activity from "./activity";
import { handleTree } from "@/utils/treeShape";
export default {
  name: "courseVideo",
  components: {
    Chat,
    test,
    ppt,
    docs,
    python,
    downloadFile,
    Activity
  },
  computed: {
    chapterTree() {
      let list = this.chapterList.map((item) => ({
        ...item,
        parentId: item.type == 1 ? 0 : item.parentId || 0,
      }));
      const tree = handleTree(list, "chapterId");
      return tree;
    },
    chapterList() {
      return this.courseInfo.chapterList || [];
    },
    currentChapter() {
      let currentChapter = this.chapterList[0];
      if (this.$route.query.chapterId) {
        currentChapter = this.chapterList.find(
          (item) => item.chapterId == this.$route.query.chapterId
        );
      }
      return currentChapter || {};
    },
  },
  watch: {
    $route() {
      this.activeTab = this.$route.query.idx
        ? String(this.$route.query.idx)
        : "1";
    },
  },
  created() {
    this.getCourse();
    this.activeTab = this.$route.query.idx
      ? String(this.$route.query.idx)
      : "1";
  },
  methods: {
    // 课程信息
    getCourse() {
      getCourseDetail(this.$route.query.courseId).then((res) => {
        if (res.code == 200) {
          this.courseInfo = res.data;
          this.$nextTick(() => this.playVideo());
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 播放视频
    playVideo(chapterId, isCatalog) {
      if (isCatalog) {
        const query = JSON.parse(JSON.stringify(this.$route.query));
        query.chapterId = chapterId;
        this.$router.push({ path: this.$route.path, query });
      }

      if (!this.currentChapter.videoId) {
        this.$message.error("该章节无视频！");
        this.noVideo = true;
        this.dp.destroy();
        return;
      }
      this.noVideo = false;
      videoAddress(this.currentChapter.chapterId).then((res) => {
        // console.log(res);
        if (res.code === 200) {
          // 清晰度
          var keyMap = {
            AUTO: "自动",
            FD: "流畅",
            LD: "标清",
            SD: "高清",
            HD: "超清",
            OD: "原画",
            "2K": "2K",
            "4K": "4K",
          };
          for (var key in res.data.playUrl) {
            var newKey = keyMap[key];
            if (newKey) {
              res.data.playUrl[newKey] = res.data.playUrl[key];
              delete res.data.playUrl[key];
            }
          }
          let quality = [];
          for (let prop in res.data.playUrl) {
            let sort = null;
            switch (prop) {
              case "4K":
                sort = 0;
                break;
              case "2K":
                sort = 1;
                break;
              case "原画":
                sort = 2;
                break;
              case "超清":
                sort = 3;
                break;
              case "高清":
                sort = 4;
                break;
              case "标清":
                sort = 5;
                break;
              case "流畅":
                sort = 6;
                break;
              case "自动":
                sort = 7;
                break;
            }
            quality.push({
              name: prop,
              url: res.data.playUrl[prop],
              type: "hls",
              sort,
            });
          }
          // 清晰度排序
          quality.sort((a1, a2) => {
            return a1.sort - a2.sort;
          });

          this.dp = new DPlayer({
            container: document.getElementById("dplayer"),
            autoplay: true,
            video: {
              type: "hls",
              quality,
              defaultQuality: 0,
            },
          });

          this.dp.switchVideo({
            url: res.data.playUrl.自动,
            pic: res.data.coverUrl,
          });
        } else {
          this.$message.error(res.msg);
          this.noVideo = true;
        }
      });
    },
    handleClick(tab) {
      const query = JSON.parse(JSON.stringify(this.$route.query));
      query.idx = tab.name;
      this.$router.push({ path: this.$route.path, query });
    },
  },
  data() {
    return {
      formatVideoTime,
      formatNumber,
      courseInfo: {},
      currentTitle: "",
      dp: {},
      activeName: "1",
      noVideo: true,
      activeTab: "1",
    };
  },
};
</script>

<style lang="less" scoped>
.course_video {
  padding-top: 30px;
  padding-bottom: 50px;
  min-height: calc(100vh - 66px - 200px);
  background-color: #f3f3f3;
  .top {
    overflow: hidden;
    .video_area {
      position: relative;
      float: left;
      width: 904px;
      border-radius: 6px;
      overflow: hidden;
      .head {
        padding: 0 23px;
        height: 48px;
        line-height: 48px;
        background-color: #fff;
        .back {
          float: left;
          cursor: pointer;
          .iconfont {
            display: inline-block;
            margin-right: 5px;
            vertical-align: top;
          }
        }
        .title {
          display: inline-block;
          max-width: 85%;
          font-size: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      #dplayer {
        width: 100%;
        height: 509px;
        vertical-align: top;
        &.dplayer:-webkit-full-screen {
          width: 100% !important;
          height: 100% !important;
        }
      }
      .cover {
        position: absolute;
        top: 48px;
        left: 0;
        width: 904px;
        height: 509px;
        z-index: 33;
      }
    }
    ::v-deep.right_menu {
      float: right;
      width: 284px;
      height: 557px;
      background-color: #fff;
      border: 1px solid #eaeaea;
      border-radius: 4px;
      .el-tabs__header {
        border: none;
      }
      .el-tabs__content {
        height: calc(100% - 39px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 4px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
          background-color: #c8c8c8;
        }
      }
      .el-tabs__item {
        width: 142px;
        font-size: 16px;
        color: #666;
        border: none;
        &.is-active {
          color: #333;
          border: none;
        }
      }
      .total {
        margin-bottom: 12px;
        font-size: 12px;
        color: #999;
        text-align: left;
      }
      .chapters {
        .disabled {
          line-height: 44px !important;
          cursor: default !important;
          color: #999;
          &:hover,
          &.playing {
            background: none !important;
            .index,
            .title,
            .time,
            .play {
              color: #999 !important;
            }
          }
        }
        .one_chapter {
          margin: 0 -15px;
          padding: 0 15px;
          height: 44px;
          line-height: 44px;
          text-align: left;
          cursor: pointer;
          &:hover,
          &.playing {
            background-color: #f6f6f6;
            .index,
            .title,
            .time,
            .play {
              color: #0d79ff;
            }
          }
          .index {
            display: inline-block;
            vertical-align: top;
          }
          .title {
            display: inline-block;
            margin-left: 8px;
            width: 55%;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: top;
          }
          .time {
            margin-left: 31px;
            margin-right: 5px;
            color: #999;
          }
          .iconfont {
            color: #999;
            &.no_video {
              float: right;
            }
          }
        }
        .one_chapter1 {
          margin: 0 -10px;
          padding: 0 5px;
          height: 30px;
          line-height: 30px;
          text-align: left;
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #999;
        }
      }
    }
  }
  .bottom {
    margin-top: 18px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    ::v-deep.el-tabs.tab {
      > .el-tabs__header {
        margin: 0;
        > .el-tabs__nav-wrap {
          &::after {
            height: 1px;
            background: #eeeeee;
          }
          > .el-tabs__nav-scroll {
            padding-left: 48px;
            > .el-tabs__nav {
              > .el-tabs__active-bar {
                background-color: #0d79ff;
              }
              > .el-tabs__item {
                height: 52px;
                line-height: 52px;
                font-size: 18px;
                padding-right: 80px;
                &:hover,
                &.is-active {
                  color: #0d79ff;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>