<template>
  <div>
    <ul class="list" v-loading="loading" v-show="!activityId">
      <li
        v-for="a in list"
        :key="a.activityId"
        @click="activityId = a.activityId"
      >
        <div
          class="tag"
          :class="[
            a.status == -1
              ? `list-type-icon-${a.type}-g`
              : `list-type-icon-${a.type}`,
          ]"
        ></div>
        <div class="right-content">
          <p class="overHidden2 fl" style="margin-top: 10px">{{ a.title }}</p>
        </div>
        <div class="time">
          {{ formatDate(a.createTime, "YYYY-MM-DD HH:mm") }}
        </div>
      </li>
    </ul>
    <XDetail v-if="activityId" :activityId.sync="activityId" />
  </div>
</template>
<script>
import { formatDate } from "@/utils/index";
import { activityList } from "@/api/activity";
import XDetail from "./detail";
export default {
  components: {
    XDetail,
  },
  props: ["courseId", "classId"],
  data() {
    return {
      formatDate,
      list: [],
      loading: false,
      activityId: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      activityList({ courseId: this.courseId, classId: this.classId }).then(
        (json) => {
          this.loading = false;
          this.list = json.rows;
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
*,
*::before,
*::after {
  box-sizing: content-box;
  margin: 0;
}
.list {
  min-height: 500px;
  :hover {
    background-color: rgb(247, 250, 252);
  }
  li {
    position: relative;
    padding-left: 30px;
    cursor: pointer;

    .tag {
      position: absolute;
      border-radius: 8px;
      top: -5px;
      left: 10px;
    }
    .right-content {
      margin-left: 62px;
      padding: 14px 20% 14px 0px;
      min-height: 40px;
      border-bottom: 1px solid rgb(242, 242, 242);
      p {
        text-align: left;
        color: rgb(58, 75, 135);
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
        max-width: 92%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
      }
    }
    .time {
      position: absolute;
      top: 50%;
      margin-top: -9px;
      right: 30px;
      font-size: 12px;
      color: rgb(168, 168, 179);
    }
  }
}

.list-type-icon-1 {
  width: 84px;
  height: 84px;
  transform: scale(0.5);
  background: url(../../../assets/activity-imgs/icons-act2.png) -10px -218px;
}

.list-type-icon-1-g {
  width: 84px;
  height: 84px;
  transform: scale(0.5);
  background: url(../../../assets/activity-imgs/icons-act2.png) -426px -322px;
}

.list-type-icon-2 {
  width: 84px;
  height: 84px;
  transform: scale(0.5);
  background: url(../../../assets/activity-imgs/icons-act2.png) -114px -218px;
}

.list-type-icon-2-g {
  width: 84px;
  height: 84px;
  transform: scale(0.5);
  background: url(../../../assets/activity-imgs/icons-act2.png) -10px -426px;
}

.list-type-icon-3 {
  width: 84px;
  height: 84px;
  transform: scale(0.5);
  background: url(../../../assets/activity-imgs/icons-act2.png) -10px -322px;
}

.list-type-icon-3-g {
  width: 84px;
  height: 84px;
  transform: scale(0.5);
  background: url(../../../assets/activity-imgs/icons-act2.png) -322px -426px;
}
</style>