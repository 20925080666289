<template>
  <div>
    <div class="btn-container" v-if="!isSignIn && activity.status == 1">
      <div v-loading="loading" class="btn btn-quick" @click="signIn">签到</div>
    </div>
    <div class="from-box" v-else>
      <i class="el-icon-success" v-if="isSignIn" />
      <p class="sign-title" :class="{ 'sign-success': isSignIn }">
        {{ signInStatusStr }}
      </p>
      <p class="sign-time" :class="{ 'sign-success': isSignIn }" v-if="sign">
        {{ formatDate(sign.createTime, "YYYY-MM-DD HH:mm") }}
      </p>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/index";
import { signInInfo, signIn } from "@/api/activity";
export default {
  props: {
    activity: Object,
  },
  data() {
    return {
      loading: false,
      formatDate,
      sign: null,
    };
  },
  computed: {
    isSignIn() {
      if (this.sign) {
        if (this.sign.status == 1 || this.sign.status == 2) {
          return true;
        }
      }
      return false;
    },
    signInStatusStr() {
      //签到状态：1已签/2代签/-1缺勤/-2事假/-3病假/-4迟到/-5早退
      if (this.sign) {
        if (this.sign.status == 1 || this.sign.status == 2) {
          return "签到成功";
        } else if (this.sign.status == -1) {
          return "缺勤";
        } else if (this.sign.status == -2) {
          return "事假";
        } else if (this.sign.status == -3) {
          return "病假";
        } else if (this.sign.status == -4) {
          return "迟到";
        } else if (this.sign.status == -5) {
          return "早退";
        }
      } else if (this.activity.status == -1) {
        return "签到已过期";
      }
      return "";
    },
  },
  created() {
    this.getSignIn();
  },
  methods: {
    getSignIn() {
      signInInfo(this.activity.activityId).then((json) => {
        this.sign = json.data;
      });
    },
    signIn() {
      this.loading = true;
      signIn(this.activity.activityId).then(() => {
        this.loading = false;
        this.getSignIn();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.from-box {
  padding: 80px 0;
  text-align: center;
  margin-top: 10px;
  background-color: white;
  border-radius: 4px;
  min-height: 420px;
  box-sizing: border-box;
  i {
    color: #3a8bff;
    font-size: 90px;
    margin-bottom: 14px;
  }
  .sign-success {
    color: #3a8bff !important;
  }
  .sign-title {
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
    color: #a8a8b3;
  }
  .sign-time {
    padding: 4px 0 20px 0;
    line-height: 20px;
    color: #a8a8b3;
    font-size: 14px;
  }
}
.btn-container {
  padding-top: 60px;
  .btn-quick {
    width: 212px;
    height: 212px;
    line-height: 212px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 40px;
    margin: 0 auto;
    cursor: pointer;
    background-repeat: no-repeat;
    background-image: url(../../../../assets/activity-imgs/circle-bg-default.png);
    background-size: 100% 100%;
    text-align: center;
  }
}
</style>