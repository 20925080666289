import http from "@/utils/http"
const baseUrl = '/app/study'

export const activityList = params => http.get(`${baseUrl}/activity`, { params })

export const activityInfo = id => http.get(`${baseUrl}/activity/${id}`)

export const signInInfo = activityId => http.get(`${baseUrl}/activity/${activityId}/sign`)

export const signIn = activityId => http.post(`${baseUrl}/activity/${activityId}/sign`)

export const selectList = activityId => http.get(`${baseUrl}/activity/${activityId}/select`)

export const rushAnswerList = activityId => http.get(`${baseUrl}/activity/${activityId}/rush/answer`)

export const rushAnswer = activityId => http.post(`${baseUrl}/activity/${activityId}/rush/answer`)
