<template>
  <div>
    <div class="btn-container" v-if="!top && activity.status == 1">
      <div v-loading="loading" class="btn-quick" @click="rushAnswer">抢</div>
    </div>
    <div v-else class="center-con">
      <div v-if="list.length == 0" class="empty-data">
        抢答已结束，没有成员抢答
      </div>
      <div v-else>
        <div class="my-rank">
          <p>我的排名：{{ top }}</p>
        </div>
        <ul class="stu-list">
          <li class="item" v-for="m in list" :key="m.memberId">
            <div class="number">
              <img v-if="m.top == 1" src="@/assets/activity-imgs/numOne.png" />
              <img
                v-else-if="m.top == 2"
                src="@/assets/activity-imgs/numTwo.png"
              />
              <img
                v-else-if="m.top == 3"
                src="@/assets/activity-imgs/numThree.png"
              />
              <template v-else>{{ m.top }}</template>
            </div>
            <div class="avatar">
              <img :src="m.memberIcon || require('@/assets/img/defaultavatar.png')" />
            </div>
            <div class="border-bottom">
              <div class="info">
                <p class="name">{{m.nickName}}</p>
              </div>
              <div class="grade">{{m.time}}秒</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { rushAnswerList, rushAnswer } from "@/api/activity";
export default {
  props: {
    activity: Object,
  },
  data() {
    return {
      loading: false,
      list: [],
      top: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      rushAnswerList(this.activity.activityId).then((json) => {
        this.list = json.data;
        this.top = json.top;
      });
    },
    rushAnswer() {
      this.loading = true;
      rushAnswer(this.activity.activityId).then(() => {
        this.loading = false;
        this.getList();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.btn-container {
  padding-top: 60px;
  .btn-quick {
    width: 212px;
    height: 212px;
    line-height: 212px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 40px;
    margin: 0 auto;
    cursor: pointer;
    background-repeat: no-repeat;
    background-image: url(../../../../assets/activity-imgs/circle-bg-default.png);
    background-size: 100% 100%;
    text-align: center;
  }
}

.center-con {
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 34px;
  padding-bottom: 104px;
  background: #ffffff;
  box-shadow: 0 2px 17px 0 rgba(239, 239, 239, 0.5);
  border-radius: 4px;
  min-height: 420px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;

  .empty-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #8a8b99;
    font-size: 14px;
  }

  .my-rank {
    padding: 20px 30px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
    p {
      font-weight: bold;
      line-height: 20px;
      color: #8a8b99;
      font-size: 14px;
    }
  }

  .stu-list {
    margin-top: 10px;
    .item {
      padding-left: 30px;
      .number {
        position: relative;
        width: 42px;
        margin-right: 6px;
        text-align: center;
        height: 44px;
        line-height: 44px;
        margin-top: 14px;
        font-size: 16px;
        float: left;
        vertical-align: middle;
        img {
          width: 14px;
          margin: 0 auto;
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          height: 31px;
          padding: 5px 0;
          box-sizing: border-box;
        }
      }

      .avatar {
        width: 44px;
        height: 44px;
        border-radius: 22px;
        margin-top: 14px;
        margin-right: 18px;
        float: left;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .border-bottom {
        border-bottom: 1px solid #f2f2f2;
        padding: 14px 30px 14px 0;
        box-sizing: border-box;
        margin-left: 110px;
        min-height: 72px;
        position: relative;
        .info {
          height: 100%;
          width: calc(100% - 200px);
          vertical-align: middle;
          display: inline-block;
          .name {
            font-size: 14px;
            color: #181e33;
            line-height: 20px;
            height: auto !important;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .grade {
          position: relative;
          letter-spacing: 0.27px;
          line-height: 44px;
          cursor: pointer;
          width: 200px;
          text-align: right;
          display: inline-block;
          vertical-align: middle;
          min-height: 44px;
          color: #8a8b99;
          font-size: 14px;
        }
      }
    }
  }
}
</style>