<template>
  <div class="container">
    <el-row style="margin-bottom: 10px">
      <el-button
        :disabled="disabledCompile"
        @click="compile"
        type="primary"
        size="medium"
        icon="el-icon-s-promotion"
      >
        运行
      </el-button>
      <el-button size="medium" icon="el-icon-s-open" @click="clear"
        >清空</el-button
      >
    </el-row>
    <el-row :gutter="20">
      <el-col :span="16">
        <codemirror
          class="code-container"
          :class="{ 'code-focus': codeFocus }"
          @focus="codeFocus = true"
          @blur="codeFocus = false"
          v-model="code"
          :options="options"
          @input="disabledCompile = false"
        ></codemirror>
      </el-col>
      <el-col :span="8">
        <el-input
          v-model="result"
          class="result-container"
          type="textarea"
          placeholder="运行结果......"
          :rows="23"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
// 引入主题,配置后生效
import "codemirror/theme/rubyblue.css";
//引入语言,配置后生效
import "codemirror/mode/python/python.js";
import { compile } from "@/api/python";
export default {
  components: {
    codemirror,
  },
  data() {
    return {
      code: '#!/usr/bin/python\nprint("Hello, World!");',
      codeFocus: false,
      disabledCompile: true,
      options: {
        indentWithTabs: true,
        indentUnit: 4, // 缩进格式
        // theme: "rubyblue", // 指定主题，对应主题库 JS 需要提前引入
        lineNumbers: true, // 是否显示行号
        //指定语言类型,如果需要编辑和显示其他语言,需要import语言js然后修改此配置
        mode: "python",
        line: true,
        styleActiveLine: true, // 高亮选中行
        hintOptions: {
          completeSingle: true, // 当匹配只有一项的时候是否自动补全
        },
      },
      result: "Hello, World!\n",
    };
  },
  methods: {
    compile() {
      this.disabledCompile = true;
      console.log(this.disabledCompile, 123);
      compile({ code: this.code }).then((json) => {
        this.result = json.compile || json.error;
      });
    },
    clear() {
      this.$confirm("确认清空?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.code = "";
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  text-align: left;
  padding: 10px;
}
.code-container {
  line-height: 1.3em;
  font-family: monospace;
  position: relative;
  overflow: hidden;
  background-color: white;
  margin-bottom: 9px;
  color: #555555;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border linear 0.2s, box-shadow linear 0.2s;
  ::v-deep .CodeMirror {
    height: 492px !important;
  }
}

.code-focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(82, 168, 236, 0.8);
}

.result-container {
  height: 480px;
}
</style>