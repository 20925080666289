<template>
  <div class="ppt" v-loading="isLoading">
    <template v-if="!empty">
      <p class="total">共{{ pptImgList.length ? pptImgList.length : 0 }}页</p>
      <!-- <el-image
        class="img"
        v-for="img in pptImgList"
        :key="img.imgId"
        :src="img.img"
        lazy
      ></el-image> -->
      <el-carousel
        class="swiper"
        trigger="click"
        :autoplay="false"
        :loop="false"
        @change="changeSwiperHeight"
        :height="`${swiperHeight}px`"
        indicator-position="outside"
        arrow="always"
      >
        <el-carousel-item
          v-for="(img, i) in pptImgList"
          :key="img.imgId"
          ref="swiper-item"
        >
          <el-image
            @load="() => i === 0 && changeSwiperHeight(0)"
            class="img"
            :src="img.img"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </template>
    <!-- 空状态 -->
    <empty-status
      :img="require('@/assets/img/emptyIcon/wushouchang@2x.png')"
      desc="暂无PPT~"
      v-else
    />
  </div>
</template>

<script>
import { getPPT } from "@/api/curriculum";
import emptyStatus from "@/components/emptyStatus/index";

export default {
  name: "ppt",
  props: {
    chapterId: Number,
  },
  watch: {
    chapterId() {
      this.getPPTList();
    },
  },
  created() {
    if (this.chapterId) {
      this.getPPTList();
    }
  },
  components: {
    emptyStatus,
  },
  methods: {
    changeSwiperHeight(index) {
      this.swiperHeight = this.$refs["swiper-item"][index].$el.offsetHeight;
    },
    // 获取PPT
    getPPTList() {
      this.isLoading = true;
      getPPT(this.chapterId).then((res) => {
        if (res.code == 200) {
          if (res.data.pptImgList.length > 0) {
            this.empty = false;
            this.pptImgList = res.data.pptImgList;
          } else {
            this.empty = true;
          }
          this.isLoading = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  data() {
    return {
      swiperHeight: 0,
      pptImgList: [],
      isLoading: false,
      empty: false,
    };
  },
};
</script>

<style lang="less" scoped>
.ppt {
  min-height: 300px;
  .total {
    padding: 22px 32px;
    color: #999;
    text-align: left;
  }
  .img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
}

::v-deep .swiper {
  .el-carousel__container {
    overflow: hidden;
    .el-carousel__item {
      height: auto;
    }
  }
}
</style>