<template>
  <div>
    <div class="main" ref="chatRef">
      <span class="comment_time">
        {{ formatDate(time, "MM-DD HH:mm") }}
      </span>
      <div class="comment_area">
        <div
          class="comment_item"
          :class="{
            my_comment: item.sender.type == 1 && item.sender.id == myId,
          }"
          v-for="item in comments"
          :key="item.id"
        >
          <img
            v-if="item.sender.icon"
            class="comment_avatar"
            :src="item.sender.icon"
            alt=""
          />
          <img
            v-else
            class="comment_avatar"
            src="@/assets/img/defaultavatar.png"
            alt
          />
          <div class="comment_content">
            <p class="name" v-if="item.sender.nickName">
              <span v-show="item.sender.id != myId">{{
                item.sender.nickName
              }}</span>
              <span class="teacher_icon" v-show="item.sender.type == 2">
                <svg class="iconfont" aria-hidden="true">
                  <use xlink:href="#iconweibiaoti-1-10"></use></svg
                >老师
              </span>
            </p>
            <p class="name" v-else>
              <span>游客</span>
            </p>
            <p class="content" v-html="item.content"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <el-popover
        placement="top-start"
        width="260"
        trigger="hover"
        popper-class="emoji_popper"
      >
        <span
          v-for="(item, index) in emojis"
          :key="index"
          @click="selEmoji(index)"
          >{{ item }}</span
        >
        <i class="iconfont iconfont_face" slot="reference">&#xe629;</i>
      </el-popover>
      <el-dropdown trigger="click" class="choose_class" v-show="!noClass">
        <span class="el-dropdown-link">
          {{ showText }}<i class="iconfont iconfont_class">&#xe626;</i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            @click.native="handleCommand({ className: '未分班', classId: 0 })"
            >未分班</el-dropdown-item
          >
          <el-dropdown-item
            v-for="item in myClass"
            :key="item.myClass"
            @click.native="handleCommand(item)"
            >{{ item.className }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>

      <div class="input_area">
        <el-input
          ref="input"
          class="input"
          size="small"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 2 }"
          resize="none"
          v-model="input"
          @focus="onFocus"
          @keydown.native="textareaKeydown"
          placeholder="说点什么吧~"
        >
        </el-input>
        <button class="send_btn" @click="handleSend">发送</button>
      </div>
    </div>
  </div>
</template>

<script>
import { myCourseClass } from "@/api/class";
import { chatHistory } from "@/api/curriculum";
import { formatDate } from "@/utils/index";
import emojiCompact from "@/utils/emoji";
import Stomp from "stompjs";

export default {
  name: "Chat",
  computed: {
    /*eslint-disable */
    time() {
      if (this.comments.length !== 0) {
        return this.comments[0].sendTime;
      }
    },
    /*eslint-enable */
    // 我的id，用于判断聊天信息是否自己
    myId() {
      return this.$store.state.personalInfo.memberId;
    },
  },
  watch: {
    comments: {
      handler() {
        let scrollHeight = this.$refs.chatRef.scrollHeight;
        this.$nextTick(() => {
          let top = scrollHeight - this.$refs.chatRef.offsetHeight;
          if (
            top == this.$refs.chatRef.scrollTop ||
            this.$refs.chatRef.scrollTop == 0
          )
            this.$refs.chatRef.scrollTop = this.$refs.chatRef.scrollHeight;
        });
      },
      deep: true,
    },
    $route() {
      this.subObj.unsubscribe();
      this.subscribe();
      this.getChatHistory();
    },
  },
  created() {
    this.connectChat();
    this.getChatHistory();
    this.getClass();
  },
  beforeDestroy() {
    // 断开连接
    this.stompClient.ws.onclose();
  },
  methods: {
    // 连接聊天室
    connectChat() {
      const protocol = location.protocol.startsWith('https') ? 'wss' : 'ws';

      const socket = new WebSocket(`${protocol}://${location.host}/app/ws`);
      const stompClient = Stomp.over(socket);
      this.stompClient = stompClient;
      if (!stompClient.connected) {
        stompClient.connect(
          {
            token: localStorage.getItem("token"),
          },
          (frame) => {
            console.log(frame);
            this.subscribe();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    // 订阅聊天频道
    subscribe() {
      let that = this;
      this.subObj = this.stompClient.subscribe(
        `/topic/chat/${this.$route.query.courseId}/${this.$route.query.chapterId}/${this.classId}`,
        (msg) => {
          console.log(msg, "topic");
          that.comments.push(JSON.parse(msg.body));
        }
      );
    },
    // 获取我的班级列表
    getClass() {
      myCourseClass(this.$route.query.courseId).then((res) => {
        if (res.code == 200) {
          this.myClass = res.data;
          if (res.data.length <= 0) {
            this.noClass = true;
          } else {
            this.noClass = false;
          }
        }
      });
    },
    // 选择班级
    handleCommand(ctx) {
      this.showText = ctx.className;
      this.classId = ctx.classId;
      this.subObj.unsubscribe();
      this.subscribe();
      this.getChatHistory();
    },
    // 选择emoji
    selEmoji(idx) {
      this.insertVariable(this.emojis[idx]);
    },
    // 输入插入光标指定位置
    async insertVariable(value) {
      const myField = this.$refs.input.$refs.textarea;
      // console.log("myField--", myField);
      if (myField.selectionStart || myField.selectionStart === 0) {
        let startPos = myField.selectionStart;
        let endPos = myField.selectionEnd;
        this.input =
          myField.value.substring(0, startPos) +
          (value || "\n") +
          myField.value.substring(endPos, myField.value.length);
        await this.$nextTick();
        myField.focus();
        myField.setSelectionRange(
          endPos + (value ? value.length : 1),
          endPos + (value ? value.length : 1)
        );
        // console.log(this.input, value)
      } else {
        this.input = value;
      }
    },
    //监听按键操作
    textareaKeydown(event) {
      if (event.ctrlKey && event.keyCode === 13) {
        //ctrl+enter
        this.insertVariable();
      } else if (event.keyCode === 13) {
        //enter
        this.handleSend();
        event.preventDefault(); // 阻止浏览器默认换行操作
        return false;
      }
    },
    // 输入框聚焦
    onFocus() {
      this.$refs.chatRef.scrollTop = this.$refs.chatRef.scrollHeight;
    },
    //发送消息
    handleSend() {
      // console.log(this.input);
      if (!this.input) {
        this.$message.error("发送消息不能为空");
        return;
      }
      this.input = this.input.replace(/\n/g, "<br />");
      this.stompClient.send(
        `/app/chat/${this.$route.query.courseId}/${this.$route.query.chapterId}/${this.classId}`,
        {},
        JSON.stringify({ msgType: "text", content: this.input })
      );
      this.$refs.chatRef.scrollTop = this.$refs.chatRef.scrollHeight;
      this.input = "";
    },
    // 聊天历史记录
    getChatHistory() {
      chatHistory(
        this.$route.query.courseId,
        this.$route.query.chapterId,
        this.classId
      ).then((res) => {
        this.comments = res.data.reverse();
        
      });
    },
  },
  data() {
    return {
      formatDate,
      stompClient: null,
      myClass: [],
      showText: "选择班级",
      input: "", // 输入内容
      comments: [], // 聊天消息列表
      emojis: emojiCompact.emojis,
      sendInputText: "",
      classId: 0, // 默认0未分班
      subObj: Object,
      noClass: true,
    };
  },
};
</script>

<style lang="less" scoped>
.main {
  margin: -15px -15px 0;
  padding: 0 12px;
  height: 405px;
  overflow-y: auto;
  .comment_time {
    margin: 12px 0;
    display: inline-block;
    font-size: 12px;
    color: #999;
  }
  .comment_area {
    .comment_item {
      margin-bottom: 10px;
      text-align: left;
      overflow: hidden;
      &.my_comment {
        text-align: right;
        .comment_avatar {
          float: right;
        }
        .content {
          min-height: 33px;
          text-align: left;
          color: #fff !important;
          background-color: #0d79ff !important;
          border-radius: 8px 0px 8px 8px !important;
          word-break: break-all;
        }
      }
      .comment_avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        float: left;
        overflow: hidden;
      }
      .comment_content {
        display: inline-block;
        margin: 0 10px;
        vertical-align: middle;
        .name {
          margin-top: 4px;
          margin-bottom: 6px;
          font-size: 12px;
          color: #666;
          .teacher_icon {
            margin-left: 8px;
            font-size: 12px;
            color: #fab727;
            vertical-align: top;
            .iconfont {
              width: 12px;
              height: 13px;
              vertical-align: middle;
            }
          }
        }
        .content {
          padding: 7px 12px;
          max-width: 178px;
          min-height: 33px;
          width: fit-content;
          font-size: 14px;
          color: #333;
          background-color: #f0f0f0;
          border-radius: 0px 8px 8px 8px;
          word-break: break-all;
        }
      }
    }
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #c8c8c8;
  }
}
.foot {
  margin: 0 -15px;
  padding: 8px 12px 11px;
  height: 96px;
  text-align: left;
  border-top: solid 1px #f3f3f3;
  .choose_class {
    float: right;
    margin-top: 5px;
    &:hover {
      .el-dropdown-link,
      .iconfont {
        color: #0d79ff;
      }
    }
    .el-dropdown-link {
      font-size: 12px;
      color: #999;
      cursor: pointer;
    }
    .iconfont_class {
      display: inline-block;
      font-size: 16px;
      color: #999;
      vertical-align: top;
    }
  }
  .iconfont_face {
    font-size: 24px;
    color: #666;
    cursor: pointer;
    &:hover {
      color: #0d79ff;
    }
  }
  .input_area {
    display: inline-block;
    position: relative;
    margin-top: 10px;
    width: 100%;
    ::v-deep.input {
      .el-textarea__inner {
        padding-left: 10px;
        padding-right: 52px !important;
        background: #ffffff;
        border-radius: 4px;
        font-family: initial;
      }
    }
    .send_btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 52px;
      height: 50px;
      color: #fff;
      background: #0d79ff;
      border-radius: 0px 4px 4px 0px;
    }
  }
}
</style>
<style lang="less">
.emoji_popper {
  display: flex;
  padding: 10px;
  height: 300px;
  justify-content: space-evenly;
  flex-wrap: wrap;
  overflow-y: auto;
  span {
    font-size: 20px;
    cursor: pointer;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #c8c8c8;
  }
}
</style>