<template>
  <div class="test" v-loading="isLoading">
    <template v-if="!testEmpty">
      <div class="after_head" v-show="testInfo.test">
        <p class="test_title">{{ testInfo.testTitle }}</p>
        <div class="test_score">
          <img src="@/assets/img/fenshu.png" alt="" />
          <span
            ><b>{{ testGrade }}</b
            >分</span
          >
        </div>
      </div>

      <!-- 单选 -->
      <div class="one_type" v-show="single.length">
        <div class="one_head">
          <h3 class="type">单选题</h3>
          <p class="num">
            (<span style="margin-right: 15px">共{{ single.length }}题</span
            ><span>{{ sum(single) }}分</span>)
          </p>
        </div>
        <div class="one_body" v-show="!testInfo.test">
          <single
            v-for="(item, idx) in single"
            :key="item.questionId"
            :question="item"
            :idx="idx + 1"
            @chooseAnswer="singleAnswers"
          />
        </div>
        <!-- 写完测试后展示 -->
        <div class="one_body" v-show="testInfo.test">
          <div
            class="one_question lookup_test"
            v-for="(single, idx) in single"
            :key="single.questionId"
          >
            <span class="question_title"
              ><span style="display: inline-block; vertical-align: middle"
                >{{ idx + 1 }}.</span
              >
              <span
                v-html="single.question"
                style="
                  display: inline-block;
                  max-width: 95%;
                  vertical-align: text-top;
                  margin-left: 5px;
                "
              ></span>
              <b class="question_score">{{ single.score }}分</b>
            </span>
            <p
              class="answer_option"
              v-for="(a, i) in single.optionList"
              :key="i"
            >
              <span class="answer_label">{{ a.sort }}.</span>
              <span
                class="answer_text"
                style="display: inline-block; margin-left: 5px"
                v-html="a.value"
              ></span>
            </p>
            <div class="answer_bar">
              <p class="my_answer">我的答案：{{ single.myAnswer }}</p>
              <p class="collect_answer">正确答案：{{ single.answer }}</p>
              <div class="answer_analysis">
                解析：
                <p style="display: inline-block" v-html="single.answerDesc"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 多选 -->
      <div class="one_type" v-show="multiple.length">
        <div class="one_head">
          <h3 class="type">多选题</h3>
          <p class="num">
            (<span style="margin-right: 15px">共{{ multiple.length }}题</span
            ><span>{{ sum(multiple) }}分</span>)
          </p>
        </div>
        <div class="one_body" v-show="!testInfo.test">
          <multiple
            v-for="(item, idx) in multiple"
            :key="item.questionId"
            :question="item"
            :idx="idx + 1"
            @chooseAnswer="multipleAnswers"
          />
        </div>
        <!-- 写完测试后展示 -->
        <div class="one_body" v-show="testInfo.test">
          <div
            class="one_question lookup_test"
            v-for="(multiple, idx) in multiple"
            :key="multiple.questionId"
          >
            <span class="question_title"
              ><span style="display: inline-block; vertical-align: middle"
                >{{ idx + 1 }}.</span
              >
              <span
                v-html="multiple.question"
                style="
                  display: inline-block;
                  max-width: 95%;
                  vertical-align: text-top;
                  margin-left: 5px;
                "
              ></span>
              <b class="question_score">{{ multiple.score }}分</b>
            </span>
            <p
              class="answer_option"
              v-for="(a, i) in multiple.optionList"
              :key="i"
            >
              <span class="answer_label">{{ a.sort }}.</span>
              <span
                class="answer_text"
                style="display: inline-block; margin-left: 5px"
                v-html="a.value"
              ></span>
            </p>
            <div class="answer_bar">
              <p class="my_answer">我的答案：{{ multiple.myAnswer }}</p>
              <p class="collect_answer">正确答案：{{ multiple.answer }}</p>
              <div class="answer_analysis">
                解析：
                <p
                  style="display: inline-block"
                  v-html="multiple.answerDesc"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 判断 -->
      <div class="one_type" v-show="judge.length">
        <div class="one_head">
          <h3 class="type">判断题</h3>
          <p class="num">
            (<span style="margin-right: 15px">共{{ judge.length }}题</span
            ><span>{{ sum(judge) }}分</span>)
          </p>
        </div>
        <div class="one_body" v-show="!testInfo.test">
          <judge
            v-for="(item, idx) in judge"
            :key="item.questionId"
            :question="item"
            :idx="idx + 1"
            @chooseAnswer="judgeAnswers"
          />
        </div>
        <!-- 写完测试后展示 -->
        <div class="one_body" v-show="testInfo.test">
          <div
            class="one_question lookup_test"
            v-for="(judge, idx) in judge"
            :key="judge.questionId"
          >
            <span class="question_title"
              ><span style="display: inline-block; vertical-align: middle"
                >{{ idx + 1 }}.</span
              >
              <span
                v-html="judge.question"
                style="
                  display: inline-block;
                  max-width: 95%;
                  vertical-align: text-top;
                  margin-left: 5px;
                "
              ></span>
              <b class="question_score">{{ judge.score }}分</b>
            </span>

            <div class="answer_bar">
              <p class="my_answer">
                我的答案：{{ judge.myAnswer == "true" ? "对" : "错" }}
              </p>
              <p class="collect_answer">
                正确答案：{{ judge.answer == "true" ? "对" : "错" }}
              </p>
              <div class="answer_analysis">
                解析：
                <p style="display: inline-block" v-html="judge.answerDesc"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="one_type" v-show="supply.length">
        <div class="one_head">
          <h3 class="type">填空题</h3>
          <p class="num">
            (<span style="margin-right: 15px">共{{ supply.length }}题</span
            ><span>{{ sum(supply) }}分</span>)
          </p>
        </div>
        <div class="one_body" v-show="!testInfo.test">
          <supply
            v-for="(item, idx) in supply"
            :key="item.questionId"
            :question="item"
            :idx="idx + 1"
            @updataAnswer="updataAnswer"
          />

          
        </div>
        <!-- 写完测试后展示 -->
        <div class="one_body" v-if="testInfo.test">
          <ViewSupply
            v-for="(item, idx) in supply"
            :key="item.questionId"
            :question="item"
            :idx="idx + 1"
            class="question_sty"
          />
        </div>
      </div>

      <div class="one_type" v-show="answer.length">
        <div class="one_head">
          <h3 class="type">简答题</h3>
          <p class="num">
            (<span style="margin-right: 15px">共{{ answer.length }}题</span
            ><span>{{ sum(answer) }}分</span>)
          </p>
        </div>
        <div class="one_body" v-show="!testInfo.test">
          <answer
            v-for="(item, idx) in answer"
            :key="item.questionId"
            :question="item"
            :idx="idx + 1"
            @updataBlankAnswer="updataBlankAnswer"
          />

          
        </div>
        <!-- 写完测试后展示 -->
        <div class="one_body" v-if="testInfo.test">
          <ViewAnswer
            v-for="(item, idx) in answer"
            :key="item.questionId"
            :question="item"
            :idx="idx + 1"
            class="question_sty"
          />
        </div>
      </div>

      <div class="btn_bar" v-show="!testInfo.test">
        <button class="btn" @click="onSubmit">提交</button>
      </div>
    </template>

    <!-- 空状态 -->
    <empty-status
      :img="require('@/assets/img/emptyIcon/wushouchang@2x.png')"
      desc="暂无随堂练习~"
      v-else
    />

    <!-- 成绩弹窗 -->
    <result-dialog
      :show="showResult"
      :score="testGrade"
      :isPaper="false"
      @close="showResult = false"
      @lookUp="showResult = false"
    />
  </div>
</template>

<script>
import { getTest, submitTest } from "@/api/curriculum";
import single from "@/components/questionTemplates/single";
import multiple from "@/components/questionTemplates/multiple";
import judge from "@/components/questionTemplates/judge";
import supply from "@/components/questionTemplates/supply";
import answer from "@/components/questionTemplates/answer";

import ViewSupply from "@/components/reviewQuestion/blanks/index";
import ViewAnswer from "@/components/reviewQuestion/answer/index";
import emptyStatus from "@/components/emptyStatus/index";
import resultDialog from "@/components/resultDialog/index";

export default {
  name: "test",
  components: {
    single,
    multiple,
    judge,
    supply,
    answer,
    emptyStatus,
    resultDialog,
    ViewSupply,
    ViewAnswer
  },
  props: {
    chapterId: Number,
  },
  watch: {
    chapterId(id) {
      this.getTest(id);
    },
  },
  methods: {
    // 随堂测试信息
    getTest(chapterId) {
      this.isLoading = true;
      getTest(chapterId).then((res) => {
        if (res.code == 200) {
          if (res.data && res.data.questionList) {
            this.testEmpty = false;
            this.testInfo = res.data;
            this.classify(res.data.questionList);
            this.testTotal = res.data.questionList.length;
            this.testGrade = res.data.score;
          } else {
            this.testEmpty = true;
          }
          this.isLoading = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 题目分类
    classify(arr) {
      this.single = [];
      this.multiple = [];
      this.judge = [];
      this.supply = []
      this.answer = []
      arr.forEach((item) => {
        switch (item.type) {
          case "radio":
            if (this.isInArray(this.single, item)) return;
            this.single.push(item);
            break;
          case "checkbox":
            if (this.isInArray(this.multiple, item)) return;
            this.multiple.push(item);
            break;
          case "boolean":
            if (this.isInArray(this.judge, item)) return;
            this.judge.push(item);
            break;
          case "supply":
            if (this.isInArray(this.supply, item)) return;
            this.supply.push(item);
            break;
          case "answer":
            if (this.isInArray(this.answer, item)) return;
            this.answer.push(item);
            break;
        }
      });
    },
    // 是否存在
    isInArray(arr, item) {
      return arr.some((e) => e.questionId === item.questionId);
    },
    // 计算总分
    sum(arr) {
      return Number(
        arr
          .reduce(function (prev, curr) {
            return curr.score + prev;
          }, 0)
          .toFixed(2)
      );
    },
    singleAnswers(val) {
      this.setAnswerArr(val);
    },
    multipleAnswers(val) {
      this.setAnswerArr(val);
    },
    judgeAnswers(val) {
      this.setAnswerArr(val);
    },
    // 填空题
    updataAnswer(val, id) {
      let answerList = [];
      val.forEach((e) => {
        let value = Object.values(e)[0];
          answerList.push(value);
      });
      let answer = answerList.join("@;@").toString();
      let obj = {};
      obj[id] = answer;
      this.setAnswerArr(obj)
    },
    updataBlankAnswer(val, id) {
      let obj = {};
      obj[id] = val;
      this.setAnswerArr(obj)
    },
    // 构建答案数组
    setAnswerArr(answer) {
      this.testAnswers = { ...this.testAnswers, ...answer };
    },
    onSubmit() {
      let num = this.testTotal - this.testAnswers.length;
      let tips = num > 0 ? `还有${num}题未作答，` : "";
      this.$confirm(`${tips}确定要提交吗？`)
        .then(() => {
          // console.log(this.testAnswers);
          submitTest(this.chapterId, this.testAnswers).then((res) => {
            if (res.code == 200) {
              this.$message.success("提交成功！");
              this.getTest(this.chapterId);
              this.showResult = true;
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
  data() {
    return {
      testInfo: {},
      single: [], // 单选
      multiple: [], // 多选
      judge: [], // 判断
      supply: [],
      answer: [],
      testAnswers: {}, // 提交的答案
      testTotal: 0, // 随堂练习总题数
      testEmpty: true,
      isLoading: false,
      showResult: false,
      testGrade: 0,
    };
  },
};
</script>

<style lang="less" scoped>
.test {
  min-height: 300px;
  text-align: left;
  .after_head {
    padding: 20px 24px 20px 32px;
    line-height: 29px;
    overflow: hidden;
    .test_title {
      float: left;
      font-size: 18px;
    }
    .test_score {
      float: right;
      img {
        margin-right: 8px;
        width: 28px;
        height: 29px;
      }
      span {
        display: inline-block;
        color: #999;
        vertical-align: bottom;
        b {
          margin-right: 4px;
          font-size: 20px;
          color: #333;
        }
      }
    }
  }
  .one_type {
    .one_head {
      padding: 16px 32px;
      background-color: #f6f6f6;
      .type,
      .num {
        display: inline-block;
        &.type {
          margin-right: 5px;
          font-size: 18px;
          color: #333;
        }
        &.num {
          font-size: 14px;
          color: #999;
        }
      }
    }
    .one_body {
      padding: 30px;
      .question_score {
        margin-left: 20px;
        color: #999;
        font-size: 14px;
        vertical-align: text-top;
      }
      .one_question {
        &.lookup_test {
          padding-bottom: 30px;
          &:last-child {
            padding: 0;
          }
        }
        .question_title {
          font-size: 14px;
          color: #333;
        }
        .answer_option {
          padding-left: 14px;
          margin-top: 20px;
          color: #333;
        }
        .answer_bar {
          margin-top: 20px;
          padding: 15px 17px;
          background-color: #f6f6f6;
          .my_answer {
            margin-bottom: 10px;
          }
          .collect_answer {
            color: #0d79ff;
          }
          .answer_analysis {
            margin: 10px 0;
            color: #333;
          }
        }
      }
    }
  }
  .btn_bar {
    padding-right: 33px;
    padding-bottom: 29px;
    text-align: right;
    .btn {
      margin-top: 33px;
      width: 180px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      background: #0d79ff;
      border-radius: 22px;
    }
  }
}
</style>