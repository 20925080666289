<template>
  <div class="download_file">
    <div class="download_file_box" v-if="!empty">
      <ul>
        <li
          v-for="item in list"
          :key="item.fileId"
          @click="toExport(item.fileUrl)"
        >
          <div class="download_file_box_img">
            <svg class="iconfont" aria-hidden="true">
              <use xlink:href="#iconweibiaoti-1-43"></use>
            </svg>
            <span>{{ item.fileTitle }}</span>
          </div>
          <div class="download_file_box_size">
            <!-- 进度条 -->
            <!-- <span class="size_class">0.3MB</span> -->
            <!-- <span class="progress_class"
              ><el-progress
                :percentage="percentage"
                :show-text="false"
              ></el-progress
            ></span> -->
          </div>
          <div class="download_file_box_ico">
            <i class="iconfont">&#xe619;</i>
          </div>
        </li>
      </ul>
    </div>
    <div class="download_file_empty" v-else>
      <empty-status
        :img="require('@/assets/img/emptyIcon/wushouchang@2x.png')"
      />
    </div>
  </div>
</template>

<script>
import { getEnclosure } from "@/api/curriculum";
import emptyStatus from "@/components/emptyStatus/index";
export default {
  components: {
    emptyStatus,
  },
  name: "downloadFile",
  props: {
    chapterId: Number
  },
  data() {
    return {
      percentage: 50,
      empty: false,
      list: [],
    };
  },
  watch: {
    chapterId(id) {
      this.getEnclosure(id);
    }
  },
  methods: {
    //获取附件
    getEnclosure(chapterId) {
      getEnclosure(chapterId).then((res) => {
        if (res.code == 200) {
          if (res.data.length != 0) {
            this.empty = false;
          } else {
            this.empty = true;
          }
          this.list = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //附件下载
    toExport(url) {
      window.location.href = url;
    },
  },
};
</script>

<style lang="less" scoped>
.download_file {
  overflow: hidden;
  position: relative;
}

.download_file_box {
  padding: 0 32px;
  min-height: 467px;
  text-align: left;
  & > ul {
    width: 100%;
    & > li {
      width: 100%;
      height: 58px;
      line-height: 58px;
      border-bottom: 1px solid #eeeeee;
      cursor: pointer;
      & > .download_file_box_img {
        width: 660px;
        height: 100%;
        float: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 10px;
        & > .iconfont {
          width: 28px;
          height: 26px;
          margin-top: 16px;
          float: left;
        }
        & > span {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          margin-left: 14px;
        }
      }
      & > .download_file_box_size {
        float: left;
        width: 430px;
        height: 100%;
        margin-right: 10px;
        & > .size_class {
          font-size: 14px;
          font-weight: 500;
          color: #999999;
          float: left;
        }
        & > .progress_class {
          float: left;
          width: 70px;
          height: 6px;
          background: #f0f0f0;
          border-radius: 3px;
          margin: 26px 0 0 14px;
        }
      }
      & > .download_file_box_ico {
        float: right;
        margin-right: 5px;
        & > .iconfont {
          font-size: 16px;
        }
      }
    }
  }
}

//空状态
.download_file_empty {
  width: 100%;
  height: 420px;
}
</style>