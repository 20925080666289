<template>
  <div class="content">
    <el-page-header
      @back="$emit('update:activityId', null)"
      :content="typeName"
    ></el-page-header>
    <div class="top-box">
      <div class="item-top">
        <div :class="`list-type list-type-icon-${info.type}`"></div>
        <h3 class="item-top-name">{{ info.title }}</h3>
        <div class="tip">
          <span class="time">{{
            formatDate(info.createTime, "YYYY-MM-DD HH:mm")
          }}</span>
          <span class="end-status">{{ statusStr }}</span>
        </div>
      </div>
      <div v-if="images.length > 0" class="img-container">
        <el-image
          style="width: 100px"
          v-for="(img, i) in images"
          :key="i"
          :src="img"
          :preview-src-list="images"
        ></el-image>
      </div>
    </div>
    <div class="content-box">
      <component :is="componentName" :activity="info" />
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/index";
import { activityInfo } from "@/api/activity";
import SignIn from "./signIn";
import XR from "./xr";
import QD from './qd'
export default {
  components: {
    SignIn,
    XR,
    QD
  },
  props: ["activityId"],
  data() {
    return {
      formatDate,
      info: {},
    };
  },
  computed: {
    typeName() {
      if (this.info.type === 1) {
        return "签到";
      } else if (this.info.type === 2) {
        return "抢答";
      } else if (this.info.type === 3) {
        return "选人";
      } else {
        return "";
      }
    },
    componentName() {
      if (this.info.type === 1) {
        return "SignIn";
      } else if (this.info.type === 2) {
        return "QD";
      } else if (this.info.type === 3) {
        return "XR";
      } else {
        return null;
      }
    },
    statusStr() {
      if (this.info.status == 1) {
        if (this.info.autoEnd == 1) {
          return `剩余${Math.ceil((new Date(this.info.endTime).getTime() - new Date().getTime()) / 1000 / 60)}分钟`;
        } else {
          return "";
        }
      } else {
        return "已结束";
      }
    },
    images() {
      if (this.info.images) {
        return JSON.parse(this.info.images);
      } else {
        return [];
      }
    },
  },
  created() {
    this.getInfo();
    this.timer = setInterval(() => {
      if (this.info.status == 1) {
        this.getInfo();
      }
    }, 1000);
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
  methods: {
    getInfo() {
      activityInfo(this.activityId).then((json) => {
        this.info = json.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  padding: 10px 20px;
  background-color: #f8f8f8;
  height: 100%;
  //   margin: 16px auto 0;
}
.top-box {
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 17px 0 rgba(239, 239, 239, 0.5);
  background: #ffffff;
  margin-top: 16px;
}
.item-top {
  text-align: left;
  padding: 22px 30px 22px 86px;
  position: relative;
  .tip {
    font-size: 12px;
    line-height: 12px;
    .time {
      display: inline-block;
      padding-right: 14px;
      border-right: 1px solid #f2f2f2;
      margin-right: 14px;
      color: #a8a8b3;
    }
    .end-status {
      color: #a8a8b3;
    }
  }

  .btns {
    position: absolute;
    right: 30px;
    top: 25px;
    cursor: pointer;
    float: right;
  }
}

.item-top-name {
  line-height: 22px;
  margin-bottom: 4px;
  width: 80%;
  font-weight: bold;
  color: #181e33;
  font-size: 16px;
}

.list-type {
  font-size: 15px;
  color: #ffffff;
  position: absolute;
  top: -6px;
  left: 8px;
  border-radius: 8px;
}
.list-type-icon-1 {
  width: 84px;
  height: 84px;
  transform: scale(0.5);
  background: url(../../../../assets/activity-imgs/icons-act2.png) -10px -218px;
}

.list-type-icon-1-g {
  width: 84px;
  height: 84px;
  transform: scale(0.5);
  background: url(../../../../assets/activity-imgs/icons-act2.png) -426px -322px;
}

.list-type-icon-2 {
  width: 84px;
  height: 84px;
  transform: scale(0.5);
  background: url(../../../../assets/activity-imgs/icons-act2.png) -114px -218px;
}

.list-type-icon-2-g {
  width: 84px;
  height: 84px;
  transform: scale(0.5);
  background: url(../../../../assets/activity-imgs/icons-act2.png) -10px -426px;
}

.list-type-icon-3 {
  width: 84px;
  height: 84px;
  transform: scale(0.5);
  background: url(../../../../assets/activity-imgs/icons-act2.png) -10px -322px;
}

.list-type-icon-3-g {
  width: 84px;
  height: 84px;
  transform: scale(0.5);
  background: url(../../../../assets/activity-imgs/icons-act2.png) -322px -426px;
}

.content-box {
  background: #ffffff;
  padding-bottom: 104px;
  min-height: 420px;
  box-sizing: border-box;
  position: relative;
  text-align: left;
}

.img-container {
  text-align: left;
  ::v-deep .el-image {
    margin: 10px;
  }
}
</style>